import getCookie from "./get-cookie"

export default async (data, url = "/tracking/events/") => {
  const config = {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
      "X-CSRFToken": getCookie("csrftoken"),
    },
    body: JSON.stringify(data),
  }

  let response = await fetch(url, config)
  return response.status > 201 ? false : response.json()
}
