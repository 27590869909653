const cropped = (str) => {
  return str.length > 30 ? `${str.substring(0, 30)}...` : str
}

const isPhoneNumber = (url) => {
  return url.startsWith("tel:")
}

export const Link = ({ url, linktext }) => {
  return (
    <>
      {url && (
        <div class="text-content">
          {isPhoneNumber(url) ? (
            <a href={url}>{linktext || cropped(url)}</a>
          ) : (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {linktext || cropped(url)}
            </a>
          )}
        </div>
      )}
    </>
  )
}
