import { ReplaceWithHint } from "./hint"
import { Link } from "./link"

export const Text = (props) => {
  const checkReplacement = (content) => {
    const hints = props.hints && Object.keys(props.hints)
    if (hints) {
      return (
        hints.map((search) => {
          return content.includes(search) ? (
            <ReplaceWithHint
              content={content}
              hint={props.hints[search]}
              replacement={search}
              onClick={() => {
                props.form.openHint(props.hints[search])
              }}
            />
          ) : (
            content
          )
        }) || content
      )
    }
    return content
  }

  return (
    <div class={`text-content ${props.class ? props.class : ""}`}>
      {props.heading && <h2>{checkReplacement(props.heading)}</h2>}
      {props.heading3 && <h3>{checkReplacement(props.heading3)}</h3>}
      {props.text && <p>{checkReplacement(props.text)}</p>}
      {props.link && (
        <p>
          <Link {...props.link} />
        </p>
      )}
    </div>
  )
}
