export const Textarea = (props) => {
  const { name, label } = props
  const storedObj = props.form.inFormValues({ name })

  const onChange = (e) => {
    const { value } = e.target

    // simply remove object from store if value is empty, i.e. toggle again.
    if (value == "") props.form.removeValue(name)
    else {
      props.form.toggleValue(
        {
          step: props.form.stepId,
          name,
          label,
          value,
        },
        true
      )
    }
  }

  return (
    <textarea
      name={props.name}
      class={props.class}
      value={storedObj && storedObj.value}
      onChange={onChange}
      maxLength="1000"
      placeholder={props.placeholder}
    />
  )
}
