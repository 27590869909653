import { useState } from "preact/hooks"
import { HintButton } from "./hint"

export const Checkboxes = (props) => {
  const classList = () => {
    let classes = ["checkboxes"]
    props.singular || classes.push("multiple")
    props.selectionLimitedBy || classes.push("selection-limited")
    props.class && classes.push(props.class)
    return classes.join(" ")
  }

  return (
    <fieldset class={classList()}>
      {props.label && <legend class="show-for-sr">{props.label}</legend>}
      {props.fields.map((f) => {
        const hint = props.hints?.[f[0]]

        return (
          <Checkbox
            key={`${props.form.stepId}-${props.name}-${f[0]}`}
            field={f}
            singular={props.singular}
            selectionLimitedBy={props.selectionLimitedBy}
            function={props.function}
            name={props.name}
            form={props.form}
            icons={props.icons ?? false}
            special_label={props.special_label ?? false}
            conditional_label={props.conditional_label ?? false}
          >
            {hint && (
              <HintButton
                title={hint.title}
                onClick={() => {
                  props.form.openHint(hint)
                }}
              />
            )}
          </Checkbox>
        )
      })}
    </fieldset>
  )
}

export const Checkbox = (props) => {
  const [value, initialLabel] = props.field,
    { name } = props

  const [disabled, setDisabled] = useState(false)

  const checkSingularCondition = (condition) => {
    let singular = false
    if (condition === "perpetrator_type") {
      singular = singlePerpetrator()
    }
    return singular
  }

  const isRequired = () => {
    return !props.form.requirementsMet(name)
  }

  const singlePerpetrator = () => {
    return props.form.inFormValues({
      name: "perpetrator_type",
      value: "single",
    })
  }

  const singular =
    typeof props.singular === "boolean"
      ? props.singular
      : checkSingularCondition(props.singular)

  const selectionLimitedBy = props.selectionLimitedBy || Infinity

  const onChange = () => {
    setDisabled(true)

    props.form.toggleValue(
      {
        step: props.form.stepId,
        name,
        label,
        value,
      },
      singular,
      selectionLimitedBy,
      props.function == "genderSwitch" ? "perpetrator_genders" : false,
    )

    props.function == "onClickNext" && props.form.showStep(1)

    setDisabled(false)
  }

  const label =
    props.conditional_label && value === "other" && singlePerpetrator()
      ? props.conditional_label
      : initialLabel

  return (
    <checkbox key={`${name}-${value}`}>
      <input
        id={`${name}-${value}`}
        type="checkbox"
        value={value}
        name={name}
        disabled={disabled}
        checked={props.form.inFormValues({ name, value }) || false}
        onChange={onChange}
      />
      <label
        for={`${name}-${value}`}
        class={
          props.icons
            ? `icon ${value} ${isRequired() && "required"}`
            : `${isRequired() && "required"}`
        }
      >
        {label}
        {props.special_label && <span>{props.special_label}</span>}
      </label>
      {props.children}
    </checkbox>
  )
}
