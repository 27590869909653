import { ContentType } from "./contents/content-type"

export const Step = (form) => {
  return (
    <step id={`step-${form.stepId}`} class={form.class}>
      {form.step.title && <h1>{form.step.title}</h1>}
      {form.step.contents &&
        form.step.contents.map((obj, i) => {
          return (
            <ContentType
              {...obj}
              form={form}
              key={`${form.stepId}-${obj.type}-${i}`}
            />
          )
        })}
    </step>
  )
}
