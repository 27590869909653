/**
 *
 * @param {HTMLElement} el
 * @param {String} key
 * @param {Function} handle
 * @returns
 */

export const keyEventHandling = (el, key, handle) => {
  el.addEventListener("keydown", (e) => {
    e.key === key && handle()
  })
}
