/* globals plausible */
export default async (data) => {
  if (typeof plausible !== "undefined") {
    window.plausible =
      window.plausible ||
      function (...args) {
        ;(window.plausible.q = window.plausible.q || []).push(args)
      }
    plausible("pageview", {
      u: `${window.location.origin}/${data}${window.location.search}`,
    })
  }
}
