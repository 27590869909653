import { useState } from "preact/hooks"

export const TextInput = ({ name, label, onChange }) => {
  const [value, setValue] = useState("")

  const _setValue = (_value) => {
    setValue(_value)
    onChange(_value)
  }

  return (
    <div class="field-wrap">
      <label for={name}>{label}</label>
      <input
        id={name}
        type="text"
        name={name}
        value={value}
        onInput={(e) => {
          _setValue(e.target.value)
        }}
      />
    </div>
  )
}
