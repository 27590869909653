import { useState, useEffect, useRef } from "preact/hooks"
import { countTo } from "../../util/count-to"

const watchCounterIntersection = (counterRef, counter) => {
  const countCallback = (entries) => {
    entries.forEach((entry) => {
      const el = entry.target
      if (entry.intersectionRatio > 0 && el.dataset.counted !== "1") {
        el.dataset.counted = "1"

        countTo(counterRef.current, counter, 3000)
      }
    })
  }

  const instance = new IntersectionObserver(countCallback)
  instance.observe(counterRef.current)
}

export const Counter = (props) => {
  const [counter, setCounter] = useState("")
  const counterRef = useRef()

  useEffect(() => {
    counter === "" &&
      props.form.ep.getData("counter").then((epData) => {
        setCounter(Number(epData.counter))
      })
  })

  useEffect(() => {
    counter !== "" && watchCounterIntersection(counterRef, counter)
  }, [counter, counterRef])

  return (
    <counter class={props.layout}>
      {props.layout == "final" && (
        <p>{props.text.replace("###", `${counter - 1}`)}</p>
      )}
      <div ref={counterRef}>{counter}</div>
      {props.layout == "big" && <p>{props.text}</p>}
    </counter>
  )
}
