import { Buttons } from "./buttons"
import { Checkboxes } from "./checkboxes"
import { Counter } from "./counter"
import { Summary } from "./summary"
import { Textarea } from "./text-area"
import { PartnerAndCounseling } from "./partner-and-counseling"
import { ContactForm } from "./contact-form"
import { Text } from "./text"
import { Download } from "./download"
import { EmergencyLinks } from "./emergency-links"

export const ContentType = (props) => {
  let el
  switch (props.type) {
    case "buttons":
      el = <Buttons {...props} />
      break
    case "checkboxes":
      el = <Checkboxes {...props} />
      break
    case "counter":
      el = <Counter {...props} />
      break
    case "summary":
      el = <Summary {...props} />
      break
    case "textarea":
      el = <Textarea {...props} />
      break
    case "partner":
      el = <PartnerAndCounseling {...props} />
      break
    case "counseling":
      el = <PartnerAndCounseling {...props} />
      break
    case "party_festival":
      el = <PartnerAndCounseling {...props} />
      break
    case "nightlife":
      el = <PartnerAndCounseling {...props} />
      break
    case "contactform":
      el = <ContactForm {...props} />
      break
    case "download":
      el = <Download {...props} />
      break
    case "emergency_links":
      el = <EmergencyLinks {...props} />
      break
    default:
      el = <Text {...props} />
      break
  }
  return el
}
