export const Download = ({ src, src_preview, title, button_label }) => {
  const getFileName = () => {
    return src.replace(/^.*?([^\\/]*)$/, "$1")
  }

  return (
    <div class="download-wrap">
      <a
        class="download-link"
        href={src}
        title={title}
        download={getFileName()}
      >
        {src_preview && (
          <div class="download-preview">
            <img src={src_preview} alt={`Preview ${title}`} />
          </div>
        )}
        <div class="download-button">
          {button_label || title || getFileName()}
        </div>
      </a>
    </div>
  )
}
