import { useEffect } from "preact/hooks"
import { keyEventHandling } from "../util/key-events"
import { ContentType } from "./contents/content-type"

export const Modal = (data) => {
  useEffect(() => {
    keyEventHandling(window, "Escape", () => {
      data.setModalData({})
    })
  })

  return (
    <modal
      class={`${data.is_hint ? "hint" : ""} ${data.color || ""}`}
      role="dialog"
      aria-modal="true"
    >
      <div class="container">
        <button
          class="modal-close"
          aria-controls="modal"
          onClick={(e) => {
            e.preventDefault()
            data.setModalData({})
          }}
        />
        {data.contents &&
          data.contents.map((obj, i) => {
            return (
              <ContentType
                {...obj}
                form={data}
                key={`modal-${obj.type}-${i}`}
              />
            )
          })}
      </div>
    </modal>
  )
}
