export const Image = ({ src, title, alt, url }) => {
  const imgTag = <img src={src} title={title} alt={alt} />

  return (
    <div class="image-wrap">
      {url ? (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {imgTag}
        </a>
      ) : (
        imgTag
      )}
    </div>
  )
}
