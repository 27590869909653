export const HintButton = (props) => {
  return (
    <button
      type="button"
      class="hint"
      onClick={props.onClick}
      title={props.title}
    >
      ?
    </button>
  )
}

export const ReplaceWithHint = (props) => {
  const content = props.content.split(props.replacement)
  return (
    <>
      {content[0]}
      <span class="hint" onClick={props.onClick}>
        {props.replacement}
      </span>
      {content[1]}
    </>
  )
}
