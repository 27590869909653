export const Message = ({ data }) => {
  return (
    <message role="dialog">
      <div class="container">
        <div class={data.severity || "warning"}>
          <span>{data.message}</span>
        </div>
      </div>
    </message>
  )
}
