export const EmergencyLinks = (props) => {
  const { emergency_links } = props
  return (
    <div class="emergency emergency-links">
      <h3>{props.title}</h3>
      {emergency_links.map((link) => {
        return (
          <a key={link} class="emergency emergency-link" href={link.href}>
            {link.label}
          </a>
        )
      })}
    </div>
  )
}
