import "./main.scss"

import { render } from "preact"
import { qs } from "./js/util/dom"
import { Form } from "./js/components/form"
import { Menu } from "./js/components/menu"
import { Endpoint } from "./js/util/endpoint"

document.addEventListener(
  "DOMContentLoaded",
  () => {
    // menu
    const menuEl = qs("#menu")
    if (menuEl)
      render(
        <Menu
          ep={new Endpoint(menuEl.dataset.endpoint)}
          contactEp={new Endpoint(menuEl.dataset.contactEndpoint)}
        />,
        menuEl
      )

    // main app
    const appEl = qs("#app-root")
    if (appEl)
      render(
        <Form
          ep={new Endpoint(appEl.dataset.endpoint)}
          contactEp={new Endpoint(appEl.dataset.contactEndpoint)}
        />,
        appEl
      )
  },
  false
)
