import { useState } from "preact/hooks"
import { Button } from "./buttons"
import { Link } from "./link"
import { Text } from "./text"
import { TextInput } from "./text-input"

export const ContactForm = ({
  heading,
  address,
  address_email,
  phone,
  email,
  button,
  warning,
  form,
  redirect,
}) => {
  const [phoneVal, setPhoneVal] = useState("")
  const [emailVal, setEmailVal] = useState("")

  const submitIntercept = () => {
    let valueStore = [
      { name: "phone", value: phoneVal },
      { name: "email", value: emailVal },
    ]
    form.onSubmit(valueStore, true, redirect)
  }

  return (
    <details>
      <summary>{heading}</summary>
      <form class="contactform">
        <Text text={heading} class="lead" />
        <Link url={`mailto:${address_email}`} linktext={address_email} />
        <Text text={address} />
        <TextInput name="phone" label={phone} onChange={setPhoneVal} />
        <TextInput name="email" label={email} onChange={setEmailVal} />
        <Button
          label={button}
          type="submit"
          onClick={submitIntercept}
          disabled={phoneVal === "" && emailVal === ""}
        />
      </form>
      <div class="warning">
        <Text text={warning.heading} class="lead" />
        <Text text={warning.text} />
      </div>
    </details>
  )
}
